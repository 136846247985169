body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  overscroll-behavior: none; /* disable pull to refresh in chrome */
  user-select: none;
  -webkit-touch-callout: none; /* disable the touch call-out pop-up menu when long-pressing on a link on IOS */
}

@keyframes fadeIn {
  from { opacity: 0; }
}
